@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  font-family: 'Inter', sans-serif;
  color: #2F3031;
  font-weight: 400;
}

.container {
  /* width: 100vw; */
  /* height: 100vh; */
  font-size: 1rem;
}

code {
  font-style: italic;
  color: cornflowerblue;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #1ac7f1;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #1ac7f1, 0 0 5px #1ac7f1;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #1ac7f1;
  border-left-color: #1ac7f1;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*Tambahan untuk css urutkan*/
.text-grey{
  color: #65686C;
}

.btn-label{
  background-color: #F5F5F5;
  color:#2F3031;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 20px;
  border-radius: 25px;
}

.btn-label.active {
  background: #ECF5FE;
  border: 1px solid #0B5FEF;
  font-weight: bold;
}